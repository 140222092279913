import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Text,
  Avatar,
  Box,
  Badge,
  Tooltip,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { TimeIcon, EmailIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { BiLike, BiChat, BiShare } from "react-icons/bi";
import { MdHeadset } from "react-icons/md"; // Importing a headset icon from Material Design
import {
  FaLinkedinIn,
  FaFacebook,
  FaComments,
} from "react-icons/fa";
import {FaXTwitter} from 'react-icons/fa6';

import axiosInstance from "../../../services/axios";
import { format, parse, differenceInCalendarDays } from "date-fns"; // for date formatting
import { ro } from "date-fns/locale"; // Import Romanian locale
import CircularScore from "./CircularScore";
import ColoredExpandableText from "./ColoredExpandableText";
import { useSelector } from "react-redux";
import Timeline from "./Timeline";

const shareOnEmail = (title, url) => {
  const subject = encodeURIComponent(`Check this out: ${title}`);
  const body = encodeURIComponent(`Take a look at this: ${url}`);
  window.open(`mailto:?subject=${subject}&body=${body}`, "_blank");
};

const FundingCard2 = ({ funding }) => {
  const [isLiked, setIsLiked] = useState(false);
  const user = useSelector((state) => state.auth.user);
  // console.log("Funding id: ", funding.funding_id);

  useEffect(() => {
    // console.log("User: ", user);
    if (user.liked_fundings.includes(funding.funding_id)) {
      setIsLiked(true);
    }
  }, [funding.funding_id, user]);

  // const funding_avatar = funding.url.includes("oportunitati")
  //   ? "/images/romanian_flag.png"
  //   : "/images/european_flag.png";

  const funding_avatar = "/images/european_flag.png";

  const formatDate = (dateString) => {
    if (!dateString) return "";
    try {
      const date = parse(dateString, "dd.MM.yyyy", new Date());
      return format(date, "PPP", { locale: ro }); // Use the Romanian locale
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  const formatDate2 = (dateString) => {
    if (!dateString) return;
    try {
      const date = new Date(dateString);
      return format(date, "PPP");
    } catch (error) {
      console.error(error);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      case "pending":
        return "yellow";
      default:
        return "gray";
    }
  };

  const getCircleColor = (score) => {
    if (score >= 75) {
      return "green"; // Green for high scores
    } else if (score >= 50) {
      return "#f1c40f"; // Yellow for medium scores
    } else {
      return "#e74c3c"; // Red for low scores
    }
  };

  // Remove the getEligibilityColor function as it's no longer needed

  const toggleLike = () => {
    setIsLiked(!isLiked);
    // console.log(funding);
    let params = {
      fundingId: funding.funding_id,
      isLiked: !isLiked,
    };
    axiosInstance
      .get(`/users/like/`, { params })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //   useEffect(() => {
  //     const token = localStorage.getItem('accessToken');
  //     if (user === null && token !== undefined) {
  //         axiosInstance.get(`/users/me`)
  //         .then(response => {
  //             dispatch(setUser(response.data));
  //         })
  //         .catch(error => {
  //             console.log(error);
  //         });
  //     }
  // }, [user, dispatch]);

  const shareOnFacebook = (url) => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;
    window.open(shareUrl, "_blank");
  };

  // Sharing on LinkedIn
  const shareOnLinkedIn = (url, title) => {
    const shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      url
    )}&title=${encodeURIComponent(title)}`;
    window.open(shareUrl, "_blank");
  };

  // Sharing on Twitter
  const shareOnTwitter = (url, text) => {
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}&url=${encodeURIComponent(url)}`;
    window.open(shareUrl, "_blank");
  };

  return (
    <Card marginBottom="4" boxShadow="xl" borderBottomRadius={5}>
      <Box
        bg={getCircleColor(funding.score)}
        h="4px"
        width="full"
        borderTopRadius={25}
      ></Box>
      <CardHeader>
        <Flex gap="4" alignItems="center">
          <Avatar size="md" src={funding_avatar} />
          <Box flex="1">
            <Heading fontSize={24}>{funding.titlu_proiect}</Heading>
          </Box>
        </Flex>
        <Divider orientation="horizontal" marginTop={2} />
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Badge colorScheme={getStatusColor(funding.status)}>
              {funding.status}
            </Badge>
            <Tooltip label={`Ultima actualizare: ${formatDate2(funding.updated_at)}`}>
              <TimeIcon ml="2" cursor="pointer" />
            </Tooltip>
          </Flex>
          <Box>
            <Text fontWeight="bold">Data start:</Text>
            <Text>{formatDate(funding.data_start)}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Deadline:</Text>
            <Text>{formatDate(funding.termen_depunere)}</Text>
          </Box>
          <Flex alignItems="center">
            <CircularScore score={funding.score} />
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody paddingTop={0}>
        <Text fontSize={16} paddingBottom={5}>
          {funding.descriere_scurta}
        </Text>
        <Timeline />
        <ColoredExpandableText
          title="Criterii de eligibilitate"
          color="gray"
          text_list={funding.eligibilitate ? [funding.eligibilitate] : []}
        />
        <ColoredExpandableText
          title="Locații eligibile"
          color="gray"
          text_list={funding.locatii_eligibile || []}
        />
        {funding.locatii_general && (
          <ColoredExpandableText
            title="Locații generale"
            color="gray"
            text_list={[funding.locatii_general]}
          />
        )}
        <ColoredExpandableText
          title="Activități eligibile"
          color="gray"
          text_list={funding.descriere_medie ? [funding.descriere_medie] : []}
        />
        <ColoredExpandableText
          title="Achiziții eligibile"
          color="gray"
          text_list={funding.achizitii ? [funding.achizitii] : []}
        />
        <Box>
          <Text padding={3} fontWeight="bold">
            Keywords:
          </Text>
          <Flex flexWrap="wrap" gap="2">
            {funding.cuvinte_cheie && funding.cuvinte_cheie.map((keyword, index) => (
              <Badge key={index} colorScheme="green">
                {keyword}
              </Badge>
            ))}
          </Flex>
        </Box>
      </CardBody>
      <CardFooter
        justify="space-between"
        flexWrap="wrap"
        sx={{
          "& > button": {
            minW: "136px",
          },
        }}
      >
        <Button
          flex="1"
          variant="ghost"
          leftIcon={<BiLike />}
          onClick={toggleLike}
          colorScheme={isLiked ? "blue" : "gray"} // Change color scheme based on the liked state
          bg={isLiked ? "blue.100" : ""} // Optional: change background color when liked
        >
          {isLiked ? "Liked" : "Like"}
        </Button>
        <Menu>
          <MenuButton
            as={Button}
            flex="1"
            variant="ghost"
            leftIcon={<BiShare />}
          >
            Share
          </MenuButton>
          <MenuList>
            <MenuItem
              icon={<Icon as={FaFacebook} />}
              onClick={() => shareOnFacebook(funding.url)}
            >
              Share on Facebook
            </MenuItem>
            <MenuItem
              icon={<Icon as={FaLinkedinIn} />}
              onClick={() => shareOnLinkedIn(funding.url)}
            >
              Share on Linkedin
            </MenuItem>
            <MenuItem
              icon={<Icon as={FaXTwitter} />}
              onClick={() => shareOnTwitter(funding.url)}
            >
              Share on X
            </MenuItem>
            <MenuItem
              icon={<Icon as={EmailIcon} />}
              onClick={() => shareOnEmail(funding.url)}
            >
              Share on email
            </MenuItem>
            {/* Add similar MenuItems for LinkedIn, Gmail, and Twitter */}
          </MenuList>
        </Menu>
        <Button
          flex="1"
          variant="ghost"
          leftIcon={<FaComments />} // Using the headset icon
          isDisabled={true} // Make the button inactive
        >
          Contact
        </Button>
      </CardFooter>
    </Card>
  );
};

export default FundingCard2;
