import React, { useState, useEffect } from "react";
import {
  CheckboxGroup,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Stack,
  Textarea,
  VStack,
  useToast,
  Container,
} from "@chakra-ui/react";

function Step2Content({ handleSubmit, data, updateData, showSteps }) {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!data.amount) {
      updateData({ ...data, amount: "medie" });
    }
  }, [data, updateData]);

  return (
    <Container
      borderRadius={10}
      marginTop={15}
      padding={5}
      maxW="800px"
      backgroundColor="white"
    >
      <>
        <Heading paddingTop={10} fontSize="xl">
          Detalii Finanțare
        </Heading>
        <VStack spacing={5} padding={5}>
          <FormControl
            id="purpose"
            isRequired
            isInvalid={!data.purpose || data.purpose === ""}
          >
            <FormLabel>Cu ce scop vei folosi banii de finanțare?</FormLabel>
            <Textarea
              placeholder="Vreau să extind suprafața cultivată a fermei pentru a produce mai multe legume."
              value={data.purpose || ""}
              onChange={(e) => updateData({ ...data, purpose: e.target.value })}
            />
          </FormControl>

          <FormControl
            id="purchases"
            isRequired
            isInvalid={!data.purchases || data.purchases === ""}
          >
            <FormLabel>Ce achiziții intenționezi să faci?</FormLabel>
            <Textarea
              placeholder="Vreau să achiziționez tractoare dotate cu tehnologie GPS pentru precizie crescută în lucrările agricole."
              value={data.purchases || ""}
              onChange={(e) =>
                updateData({ ...data, purchases: e.target.value })
              }
            />
          </FormControl>

          <FormControl
            id="amount"
            isRequired
            isInvalid={!data.amount || data.amount === ""}
          >
            <FormLabel>
              Ce sumă ar fi potrivită pentru a îndeplini obiectivele?
            </FormLabel>
            <Select
              placeholder="Selectează intervalul"
              value={data.amount || ""}
              onChange={(e) => updateData({ ...data, amount: e.target.value })}
              defaultValue="medie"
            >
              <option value="micro">0-10.000 €</option>
              <option value="mica">10.000-50.000 €</option>
              <option value="medie">50.000-250.000 €</option>
              <option value="mare">250.000-2.000.000 €</option>
              <option value="mega">peste 2.000.000 €</option>
            </Select>
          </FormControl>

          <FormControl
            id="financingTypes"
            isRequired
          >
            <FormLabel>Cu ce tipuri de finanțări ești de acord?</FormLabel>
            <CheckboxGroup
              colorScheme="green"
              defaultValue={["grant", "loan"]}
              value={data.financingTypes || ["grant", "loan"]}
              onChange={(value) =>
                updateData({ ...data, financingTypes: value })
              }
            >
              <Stack spacing={2}>
                <Checkbox value="grant" isChecked>
                  Fonduri naționale/europene
                </Checkbox>
                <Checkbox value="loan" isChecked>
                  Credite bancare
                </Checkbox>
                <Checkbox value="equity" isDisabled>
                  Finanțare de tip equity (disponibil în curând)
                </Checkbox>
                <Checkbox value="crowdfunding" isDisabled>
                  Finanțare de tip crowdfunding (disponibil în curând)
                </Checkbox>
                <Checkbox value="grant-uri" isDisabled>
                  Grant-uri (disponibil în curând)
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </FormControl>
        </VStack>
      </>
    </Container>
  );
}

export default Step2Content;
