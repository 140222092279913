import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { signOutAPI } from "../../action";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../reducers/authReducer"; // Adjust the path as necessary
import { FaSignOutAlt } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { FaSort, FaFilter } from "react-icons/fa";
import axiosInstance from "../../services/axios";
import SearchDropdownContent from "./SearchDropdown";

const Container = styled.div`
  height: 70px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 0 10px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  transition: top 0.3s;
  @media (min-width: 768px) {
    top: 0 !important; // Always visible on large screens
  }
`;

const Nav = styled.nav`
  margin-left: auto;
  display: block;
  @media (max-width: 768px) {
    position: fixed;
    left: 0;
    bottom: 0;
    background: white;
    width: 100%;
    transition: bottom 0.3s;
    background: white;
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  max-width: 1300px; //1128px;
`;

const Logo = styled.span`
  // margin-right: 100px;
  // margin-left: -105px;
  width: 50px;
  min-width: 50px;
  border-radius: 30%;
  font-size: 100%;
  @media (max-width: 768px) {
    padding: 2px;
    // margin-left: -30px;
  }
  // padding: 1%;
`;

// const Search = styled.div`
//   opacity: 1;
//   flex-grow: 1;
//   display: flex;
//   align-items: center;
//   position: relative;

//   & > div {
//     display: flex;
//     align-items: center;
//     width: 100%;

//     input {
//       border: none;
//       box-shadow: none;
//       border-radius: 2px;
//       color: rgba(0, 0, 0, 0.9);
//       padding: 0 8px 0 40px;
//       line-height: 1.75;
//       font-weight: 400;
//       font-size: 14px;
//       height: 34px;
//       vertical-align: text-top;
//       width: 100%;
//       max-width: 200px;
//       transition: max-width 0.3s ease-in-out;

//       &:focus {
//         max-width: 100%;
//       }
//     }
//   }
// `;

const SearchDropdown = styled.div`
  // display: ${({ visible }) => (visible ? "block" : "none")};
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 100;
  width: 100%;
  margin-top: 10px;
  border-radius: 15px;

  .filter-group {
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px;

    label {
      font-size: 14px;
      margin-bottom: 5px;
    }

    input,
    select {
      padding: 5px;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 14px;
      // width: 100%;
    }
  }

  @media (min-width: 768px) {
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  @media (max-width: 768px) {
    padding: 10px;
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const Search = styled.div`
  opacity: 1;
  flex-grow: 1;
  display: flex;
  align-items: center;

  .searchBar {
    display: flex;
    align-items: center;
    flex-grow: 1;

    input {
      flex-grow: 1;
      background: #eef3f8;

      border: none;
      box-shadow: none;
      border-radius: 2px;
      color: rgba(0, 0, 0, 0.9);
      // width: 680px;
      padding: 0 8px 0 40px;
      line-height: 1.75;
      font-weight: 400;
      font-size: 14px;
      height: 34px;
      vertical-align: text-top;
      transition: max-width 0.3s ease-in-out;
      width: 100%;
      max-width: 300px;
      margin-left: 8px;

      &:focus {
        max-width: 100%;
      }
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
  }
`;
const SearchHistory = styled.div`
  position: absolute;
  top: 100%;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 100;
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
  max-height: 200px;
  overflow-y: auto;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 80%;
  }

  .search-item {
    width: 100%;
    display: flex;
    padding: 8px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: #f9f9f9;
    }

    img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    span {
      font-size: 14px;
      color: #333;
    }

    &:last-child {
      border-bottom: none;
    }
  }
`;

const SearchIcon = styled.div`
  margin-right: -40px;
  margin-left: 10px;

  width: 40px;
  z-index: 1;
  // position: absolute;
  // top: 10px;
  // left: 5px;
  border-radius: 0 2px 2px 0;
  // margin: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchSettings = styled.div`
  // margin-left: 10px;
  cursor: pointer;
  // display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 768px) {
    margin-left: 5px;
  }
`;

const FilteringGroup = styled.div`
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .sorting-options {
    display: flex;

    label {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: 14px;

      input {
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    padding: 5px;

    h4 {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .sorting-options {
      label {
        font-size: 12px;
      }
    }
  }
`;

const SortingGroup = styled.div`
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .sorting-options {
    // display: flex;
    flex-direction: column;

    label {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: 14px;

      input {
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    padding: 5px;

    h4 {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .sorting-options {
      label {
        font-size: 12px;
      }
    }
  }
`;

const NavListWrap = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  list-style-type: none;
  justify-content: space-between;
  .active {
    span {
      color: rgba(0, 0, 0, 1); /* Change text color to black */
    }
    font-weight: 600;
    span::after {
      content: "";
      transform: scaleX(1);
      border-bottom: 2px solid var(--white, #fff);
      position: absolute;
      left: 0;
      bottom: 0;
      transition: transform 0.2s ease-in-out;
      width: 100%;
      border-color: purple; //rgba(0, 0, 0, 0.9)
    }
  }
`;

const NavList = styled.li`
  display: flex;
  align-items: center;
  a {
    align-items: center;
    background: transparent;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
    justify-content: center;
    line-height: 1.5;
    // min-height: 52px;
    min-width: 80px;
    position: relative;
    text-decoration: none;
    span {
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      text-align: center;
    }
    @media (max-width: 768px) {
      min-width: 50px;
      font-size: 9px;
      span > img {
        width: 40%;
      }
    }
  }
  &:hover,
  &:active {
    a {
      span {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
`;

const SignOut = styled.div`
  // position: absolute;
  // top: 45px;
  background: white;
  border-radius: 0 0 5px 5px;
  width: 100px;
  // height: 40px;
  font-size: 16px;
  text-align: center;
  // transition-duration: 167ms;
  // display: none;
  // z-index: 15;
`;

const SignOutMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    padding-left: 1rem;
    font-size: 14px;
  }
`;

// const User = styled(NavList)`
//   a > img {
//     border-radius: 50%;
//     width: 25px;
//     height: 25px;
//   }
//   span {
//     display: flex;
//     align-items: center;
//   }
//   &:hover {
//     ${SignOut} {
//       @media (min-width: 768px) {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//       }
//     }
//   }
// `;

const User = styled(NavList)`
  position: relative; // Ensure dropdown is positioned relative to the user icon
  cursor: pointer;
  a > img {
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
  span {
    display: flex;
    align-items: center;
  }
`;

const Work = styled(User)`
  border-left: 1px solid rgba(0, 0, 0, 0.08);
`;

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 130%;
  right: 0;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 100;
  min-width: 250px;
  border-radius: 5px;

  @media (max-width: 768px) {
    top: auto;
    bottom: 130%;
    right: 10%;
  }

  .profile-section {
    display: flex;
    align-items: center;
    padding: 10px 0;

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.9);
    }

    img {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      margin-right: 10px;
    }

    .profile-info {
      display: flex;
      flex-direction: column;

      h4 {
        font-size: 16px;
        margin: 0;
      }

      p {
        font-size: 14px;
        margin: 0;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  hr {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 10px 0;
  }

  .account-section,
  .manage-section {
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 14px;
      // margin: 0 0 10px;
      color: rgba(0, 0, 0, 0.9);
    }

    a {
      // padding: 5px 0;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;

      &:hover {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
`;

const SignOutSection = styled.div`
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${SignOut} {
    display: flex;
    align-items: center;
    // padding: 10px 0;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    width: 100%;

    &:hover {
      color: rgba(0, 0, 0, 0.9);
    }

    svg {
      margin-right: 10px;
    }
  }
`;

const NotificationsDropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 130%;
  right: 0;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 100;
  min-width: 200px;
  max-width: 400px;
  max-height: 400px;
  border-radius: 5px;
  overflow-y: auto;

  @media (max-width: 768px) {
    top: auto;
    bottom: 130%;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.9);
  }

  .notification-item {
    // padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`;

function Header({setSearchResults, user}) {
  // console.log("Header----------");
  // console.log(setSearchResults);
  // console.log(user);
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current location

  // State for controlling the dropdown visibility
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false);

  // States for controlling the visibility of the header on scroll
  const [lastScrollY, setLastScrollY] = useState(0);
  const [visible, setVisible] = useState(true);

  const [searchDropdownVisible, setSearchDropdownVisible] = useState(false);
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);

  const [currentFilters, setCurrentFilters] = useState({
    status: "all",
    region: "all",
    startDate: null,
    endDate: null,
    sortBy: "relevance",
    sortOrder: "desc",
  });

  const [searchQuery, setSearchQuery] = useState("");
  // const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    // Load filters from local storage on component mount
    const savedFilters = JSON.parse(localStorage.getItem("searchFilters"));
    if (savedFilters) {
      setCurrentFilters(savedFilters);
    }
  }, []);

  useEffect(() => {
    // Save filters to local storage whenever they change
    localStorage.setItem("searchFilters", JSON.stringify(currentFilters));
  }, [currentFilters]);

  const handleSearch = useCallback(async (filters) => {
    try {
      const response = await axiosInstance.get("/fundings/load/0", {
        params: {
          company_id: user?.companies[0],
          ...filters,
          text: searchQuery,
        },
        withCredentials: true,
      });
      console.log("Search response:", response.data);
      setSearchResults(response.data, filters);
    } catch (error) {
      console.error("Error during search:", error);
    }
  }, [searchQuery, setSearchResults, user]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchInputKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch({});
    }
  };

  useEffect(() => {
    const savedSearchHistory =
      JSON.parse(localStorage.getItem("searchHistory")) || [];
    setSearchHistory(savedSearchHistory);
  }, []);

  const toggleSearchDropdown = () => {
    setSearchDropdownVisible(!searchDropdownVisible);
  };

  // Function to toggle the notifications dropdown visibility
  const toggleNotificationsDropdown = () => {
    setNotificationsVisible(!notificationsVisible);
  };

  // Memoize the closeNotificationsDropdown function
  const closeNotificationsDropdown = useCallback((e) => {
    if (notificationsVisible && !e.target.closest(".notifications-dropdown")) {
      setNotificationsVisible(false);
    }
  }, [notificationsVisible]);

  // Use the memoized function in the effect
  useEffect(() => {
    document.addEventListener("click", closeNotificationsDropdown);
    return () => document.removeEventListener("click", closeNotificationsDropdown);
  }, [closeNotificationsDropdown]);

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // Function to close the dropdown when clicking outside
  const closeDropdown = (e) => {
    if (dropdownVisible && !e.target.closest(".user-dropdown")) {
      setDropdownVisible(false);
    }
  };

  // Add event listener for clicks outside
  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, [dropdownVisible]);

  const handleScroll = () => {
    if (window.innerWidth < 1500) {
      // Apply only on small screens
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setVisible(false); // Hide when scrolling down
      } else {
        setVisible(true); // Show when scrolling up
      }
      setLastScrollY(currentScrollY);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 1500) {
      window.addEventListener("scroll", handleScroll, { passive: true });
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY, visible]);

  return (
    <Container style={{ top: visible ? "0" : "-70px" }}>
      <Content>
        <Logo>
          <Link to="/feed">
            <img src="/images/logo.svg" alt="" />
          </Link>
        </Logo>
        <Search>
          <div className="searchBar">
            <SearchIcon>
              <img src="/images/search-icon.svg" alt="Search" style={{ marginLeft: "5px" }} />
            </SearchIcon>
            <input
              type="text"
              placeholder="Search for funding opportunities"
              value={searchQuery}
              onChange={handleSearchInputChange}
              onKeyPress={handleSearchInputKeyPress}
              style={{
                height: "40px",
                borderRadius: "20px",
                marginRight: "-5px",
              }}
            />
            <NavList onClick={toggleSearchDropdown}>
              <Link>
                <img src="/images/filter.svg" alt="Settings" style={{ marginTop: "5px" }} />
                <span>Filtreaza</span>
              </Link>
            </NavList>
          </div>
          {searchDropdownVisible && (
            <SearchDropdown>
              <SearchDropdownContent 
                onSearch={handleSearch}
                initialFilters={currentFilters}
              />
            </SearchDropdown>
          )}
        </Search>
        {/* <SignOutMobile onClick={() => signOut()}>
          <a>Sign Out</a>
        </SignOutMobile> */}
        <Nav style={{ bottom: visible ? "0" : "-60px" }}>
          <NavListWrap>
            <NavList className={location.pathname === "/feed" ? "active" : ""}>
              <Link to="/feed">
                <img src="/images/nav-home.svg" alt="" />
                <span>Acasa</span>
              </Link>
            </NavList>
            {/* <NavList>
              <a href="/feed">
                <img src="/images/nav-network.svg" alt="" />
                <span>My Network</span>
              </a>
            </NavList> */}
            <NavList
              className={location.pathname === "/savedFundings" ? "active" : ""}
            >
              <Link to="/savedFundings">
                <img src="/images/nav-jobs.svg" alt="" />
                <span>Salvate</span>
              </Link>
            </NavList>
            <NavList>
              <Link to="/feed">
                <img src="/images/nav-work.svg" alt="" />
                <span>Evenimente</span>
              </Link>
            </NavList>
            <NavList
              className="notifications-dropdown"
              onClick={toggleNotificationsDropdown}
              style={{
                position: "relative", // Ensure dropdown is positioned relative to the user icon
                cursor: "pointer",
              }}
            >
              <a>
                <img src="/images/nav-notifications.svg" alt="" />
                <span>Notificari</span>
              </a>
              {notificationsVisible && (
                <NotificationsDropdown className="dropdown">
                  <h4>Notificari</h4>
                  <div className="notification-item">Nu ai notificari noi</div>
                </NotificationsDropdown>
              )}
            </NavList>

            <User className="user-dropdown" onClick={toggleDropdown}>
              <a>
                {user && user.photoURL ? (
                  <img src={user.photoURL} alt="" />
                ) : (
                  <img src="/images/user.svg" alt="" />
                )}
                <span>
                  Me <img src="/images/down-icon.svg" alt="" />
                </span>
              </a>
              {dropdownVisible && (
                <Dropdown className="dropdown">
                  <div className="profile-section">
                    <Link to="/profile">
                      {user && user.photoURL ? (
                        <img src={user.photoURL} alt="" />
                      ) : (
                        <img src="/images/user.svg" alt="" />
                      )}
                      <div className="profile-info">
                        <h4>{user?.displayName || "Nume "}</h4>
                        <p>Vezi profil</p>
                      </div>
                    </Link>
                  </div>
                  <hr />
                  <div className="account-section">
                    <h4>Cont</h4>
                    <Link to="/settings">Cookies & Privacy</Link>
                    <Link to="/contact">Contact</Link>
                    <Link to="/aboutus">Despre noi</Link>
                    <Link to="/blog">Blog</Link>
                  </div>
                  <hr />
                  <div className="manage-section">
                    <h4>Management</h4>
                    <Link to="/posts">Datele firmei</Link>
                    <Link to="/jobs">Datele companiei</Link>
                  </div>
                  <hr />
                  <SignOutSection>
                    <SignOut
                      onClick={() => {
                        dispatch(logout());
                      }}
                    >
                      <FaSignOutAlt size={16} />
                      <span>Sign Out</span>
                    </SignOut>
                  </SignOutSection>
                </Dropdown>
              )}
            </User>

            {/* <Work>
              <a>
                <img src="/images/nav-work.svg" alt="" />
                <span>
                  Work <img src="/images/down-icon.svg" alt="" />
                </span>
              </a>
            </Work> */}
          </NavListWrap>
        </Nav>
      </Content>
    </Container>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     user: state.auth.user,
//   };
// };

// const mapDispatchToProps = (dispatch) => ({
//   signOut: () => dispatch(signOutAPI()),
//   search: () => dispatch(())
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Header);

export default Header;