import React, { useEffect, useState } from "react";
import FinanceOption from "./FinanceOption";
import NavigationDrawer from "./NavigationDrawer2";
import Notifications from "./Notifications";
import Events from "./Events";
import Header from "../Feed/Header";
import styles from "./Feed2.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { loadUserFromStorage } from "../../reducers/authReducer";
import Chat from "./Chat";

function Feed2() {
  const dispatch = useDispatch();
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    dispatch(loadUserFromStorage()); // Load user from local storage
  }, [dispatch]);

  const user = useSelector((state) => state.auth.user);
  const isLoading = useSelector((state) => state.auth.isLoading); // Check loading state

  if (isLoading) {
    return <div>Loading...</div>; // Show loading indicator
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  if (user && !user.is_verified) {
    return <Navigate to="/verify" replace />;
  }

  return (
    <div>
      <Header setSearchResults={setSearchResults} user={user}/>
      <div className={styles.centered}>
        <div className={styles.app}>
          <NavigationDrawer className={styles.leftContainer} />
          <FinanceOption searchResults={searchResults}className={styles.centerContainer} />
          <div className={styles.rightContainer}>
            <Notifications />
            {/* <Events /> */}
          </div>
        </div>
      </div>
      <Chat fundingId={"index"}/>
    </div>
  );
}

export default Feed2;
