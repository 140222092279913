import React, { useState } from "react";
import {
  MdPayment,
  MdWeb,
  MdPeople,
  MdOutlineSettings,
  MdOutlineMonetizationOn,
  MdMenu,
  MdClose,
} from "react-icons/md";
import Logo from "./assets/logo.svg";
import headerStyles from "./Header.module.css";
import { Link, useLocation } from "react-router-dom";

export default function Header({
  scrollToHero,
  scrollToSuccessStories,
  scrollToFinancingOptions,
  scrollToHowItWorks,
  scrollToPricing,
  scrollToFundInfo,
}) {
  const location = useLocation();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleNavigation = (path) => {
    try {
      if (location.pathname === "/") {
        if (path === "/blog") {
          window.location.href = "/blog";
        } else {
          switch (path) {
            case "/how-it-works":
              scrollToHowItWorks();
              break;
            case "/pricing":
              scrollToPricing();
              break;
            case "/financing-options":
              scrollToFinancingOptions();
              break;
            case "/aboutus":
              scrollToFundInfo();
              break;
            default:
              break;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    setDrawerOpen(false); // Close the drawer on navigation
  };

  const navItems = [
    {
      Icon: MdOutlineSettings,
      text: "Cum functioneaza?",
      path: "/how-it-works",
    },
    {
      Icon: MdOutlineMonetizationOn,
      text: "Optiuni de finantare",
      path: "/financing-options",
    },
    { Icon: MdPeople, text: "Despre noi", path: "/aboutus" },
    { Icon: MdWeb, text: "Blog", path: "/blog" },
  ];

  return (
    <header className={headerStyles.header}>
      <Link to="/">
        <img src={Logo} alt="Logo" className={headerStyles.logo} />
      </Link>

      <nav
        className={`${headerStyles.navigation} ${
          isDrawerOpen ? headerStyles.openDrawer : ""
        }`}
      >
        {navItems.map((item) => (
          <Link
            key={item.path}
            to={
              location.pathname !== "/" &&
              (item.path === "/blog" || item.path === "/aboutus")
                ? item.path
                : "/#" + item.path.substring(1)
            }
            className={`${headerStyles.navItem} ${
              location.pathname === item.path ? headerStyles.active : ""
            }`}
            onClick={() => handleNavigation(item.path)}
          >
            <item.Icon className={headerStyles.navIcon} />
            <div className={headerStyles.navItemText}>{item.text}</div>
          </Link>
        ))}
      </nav>
      <div className={headerStyles.authButtonsDrawer}>
        <div className={headerStyles.burgerIcon} onClick={toggleDrawer}>
          {isDrawerOpen ? <MdClose /> : <MdMenu />}
        </div>
        <Link to="/signup" className={headerStyles.signupBtn}>
          Sign up
        </Link>
        <Link to="/signin" className={headerStyles.loginBtn}>
          Log in
        </Link>
      </div>
    </header>
  );
}
