import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FaBuilding, FaRegBookmark } from "react-icons/fa";
import leftStyle from "./NavigationDrawer2.module.css";
import { setCompaniesSuccess } from "../../reducers/authReducer";
import axiosInstance from "../../services/axios";

function Left() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const companies = useSelector((state) => state.auth.companies);
  let photoUrl = user && user.profile_picture ? user.profile_picture : "/images/user.svg";

  useEffect(() => {
    if (!companies) {
      axiosInstance
        .get("/companies/getAll", { withCredentials: true })
        .then((res) => {
          dispatch(setCompaniesSuccess(res.data));
        })
        .catch((error) => {
          console.error("Could not fetch companies", error);
        });
    }
  }, [companies, dispatch]);

  return (
    <>
      <button className={leftStyle.menuButton}></button>
      <div className={leftStyle.navDrawer}>
        <div className={leftStyle.artCard}>
          <div className={leftStyle.userInfo}>
            <div className={leftStyle.cardBackground} />
            <Link to="/profile">
              <div
                className={leftStyle.photo}
                style={{ backgroundImage: `url(${photoUrl})` }}
              />
              <div className={leftStyle.link}>
                Salutare{user && user.first_name ? ", " + user.first_name : ""}!
              </div>
            </Link>
            <Link to="/profile">
              <div className={leftStyle.addPhotoText}>
                Configureaza profilul
              </div>
            </Link>
          </div>
          <div className={leftStyle.divider} />
          <div className={leftStyle.section}>
            <div className={leftStyle.sectionHeader}>Companiile mele</div>
            <div className={leftStyle.companiesList}>
              {companies && companies.length > 0 ? (
                <>
                  {companies.map((company) => (
                    <div key={company._id} className={leftStyle.navItem}>
                      <Link
                        to={`/company/${company._id}`}
                        className={leftStyle.companyLink}
                      >
                        {company.company_name}
                      </Link>
                    </div>
                  ))}
                  <Link to="/onboard2" className={leftStyle.addCompanyMessage}>
                    Mai adauga o companie
                  </Link>
                </>
              ) : (
                <Link to="/onboard2" className={leftStyle.addCompanyMessage}>
                  Adauga o companie
                </Link>
              )}
            </div>
          </div>
          <div className={leftStyle.divider} />
          {/* Saved Fundings */}
          <div className={leftStyle.section}>
            <Link to="/savedFundings" className={leftStyle.savedFundingsLink}>
              <FaRegBookmark className={leftStyle.icon} />
              Finantari salvate
            </Link>
            {/* <div className={leftStyle.savedFundingsBadge}>10</div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Left;
