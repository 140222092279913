import React, { useState } from "react";
import styles from "./Notifications.module.css";

const Notificari = () => {
  // Keeping track of selected company and request
  const [selectedEvent, setSelectedEvent] = useState("Crowdfunding campaign");

  const events = [
    {
      title: "Innovators for Children 2024",
      subtitle: "19.08.24",
      image: "/images/innovators_for_children.svg", // replace with actual paths
      link: "https://innovatorsforchildren.org/",
    },
    {
      title: "Angel Investors Retreat",
      subtitle: "15.19.24",
      image: "/images/rubikhub.png",
      link: "https://rubikhub.ro/angel-investors-retreat/ ",
    },
    {
      title: "Rubik Garage",
      subtitle: "17.09.24",
      image: "/images/rubikhub.png",
      link: "https://rubikhub.ro/rubik-garage/%C2%A0",
    },
    {
      title: "SEE CleanTech Nexus Accelerator",
      subtitle: "19.09.24",
      image: "/images/cleantech.png",
      link: "https://techcelerator.co/cleantech/%C2%A0",
    },
    {
      title: "Tineri Lideri pentru Agricultură",
      subtitle: "01.10.24",
      image: "/images/cfro2.png",
      link: "https://cfro.ro/tineri-lideri"
    },
    {
      title: "Romania ClimAccelerator",
      subtitle: "13.10.24",
      image: "/images/climaccelerator.png",
      link: "https://www.impacthub.ro/climaccelerator/"
    },
    {
      title: "Climathon 2024",
      subtitle: "01.11.24",
      image: "/images/climathon.png",
      link: "https://www.impacthub.ro/climathon-2024/"
    },
  ];

  return (
    <div className={styles.eventsDrawer}>
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <span>Evenimente</span>
        </div>
        {events.map((event, index) => (
          <div
            key={index}
            className={`${styles.navItemEvents} ${
              event.title === selectedEvent ? styles.selectedNavItem : ""
            }`}
            onClick={() => setSelectedEvent(event.title)}
          >
            <div className={styles.eventImage}>
              <img src={event.image} alt={event.title} />
            </div>
            <div className={styles.eventDetails}>
              <span className={styles.eventTitle}>{event.title}</span>
              <span className={styles.eventSubtitle}>Termen: {event.subtitle}</span>
            </div>
          </div>
        ))}
        {/* <div className={styles.discoverAll}>
          <a href="#">Descopera</a>
        </div> */}
      </div>
    </div>
  );
};

export default Notificari;
