import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import FundingCard2 from "./FundingCard/FundingCard2";
import axiosInstance from "../../services/axios";

function LikedFundingScroll(user) {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

//   const user = useSelector((state) => state); // state.auth.user
//   console.log("User: ", user);

  const fetchMoreData = () => {
    if (!hasMore) return;

    const url = `/fundings/loadLiked/${items.length}`;

    axiosInstance
      .get(url)
      .then((response) => response.data)
      .then((newItems) => {
        if (newItems.length === 0) {
          setHasMore(false);
          return;
        }
        // console.log("New items: ", newItems);
        setItems((prevItems) => [...prevItems, ...newItems]);
        // console.log(newItems[0]);
      })
      .catch((error) => console.error("Error:", error));
  };

  // Fetch initial data on mount
  useEffect(() => {
    fetchMoreData();
  }, []);

  return (
    <div>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {items.map((item, index) => (
          <FundingCard2 key={index} funding={item} user={user}/>
        ))}
      </InfiniteScroll>
    </div>
  );
}

export default LikedFundingScroll;
