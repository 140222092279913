import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Footer.module.css";
import Logo from "./assets/logo.svg";
import { FaLinkedin, FaFacebook, FaTiktok, FaInstagram } from "react-icons/fa";
import SendEmail from "./assets/group-6.svg";

export default function Footer({
  scrollToHero,
  scrollToSuccessStories,
  scrollToFinancingOptions,
  scrollToHowItWorks,
  scrollToPricing,
  scrollToFundInfo,
}) {
  const location = useLocation();

  const handleNavigation = (path) => {
    try {
      if (location.pathname === "/") {
        if (path === "/blog") {
          window.location.href = "/blog";
        } else {
          switch (path) {
            case "/#how-it-works":
              scrollToHowItWorks();
              break;
            case "/#pricing":
              scrollToPricing();
              break;
            case "/#financing-options":
              scrollToFinancingOptions();
              break;
            case "/#aboutus":
              scrollToFundInfo();
              break;
            default:
              break;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.media}>
          <img src={Logo} alt="Logo" className={styles.logo} />
          <div className={styles.socialContainer}>
            <p className={styles.socialText}>Follow us:</p>
            <a
              href="https://www.facebook.com/profile.php?id=61557965881172"
              target="_blank"
              rel="noreferrer"
              className={styles.socialLink}
            >
              <FaFacebook />
            </a>
            {/* <a href="#" className={styles.socialLink}>
              <FaInstagram />
            </a> */}
            <a
              href="https://www.linkedin.com/company/ctrl-fund/about/"
              target="_blank"
              rel="noreferrer"
              className={styles.socialLink}
            >
              <FaLinkedin />
            </a>
            {/* <a href="#" className={styles.socialLink}>
              <FaTiktok />
            </a> */}
          </div>
        </div>
        <div className={styles.column}>
          <h3 className={styles.title}>Date de contact</h3>
          <p className={styles.text}>
            Ctrl.fund este un site detinut si operat de FINACCESS SRL
            (inregistrata in România)
            <br />
            <br />
            CUI: 42171928
            <br />
            J22/242/2020
          </p>
        </div>

        <div className={styles.column}>
          <h3 className={styles.title}>Quick Links</h3>
          <ul className={styles.list}>
            {/* <li>
              <a href="#" className={styles.link}>
                Preturi
              </a>
            </li> */}
            <li>
              <Link
                to="#"
                className={styles.link}
                onClick={() => handleNavigation("/#financing-options")}
              >
                Optiuni de finantare
              </Link>
            </li>
            <li>
              <Link
                to="/#how-it-works"
                className={styles.link}
                onClick={() => handleNavigation("/#how-it-works")}
              >
                Cum functioneaza?
              </Link>
            </li>
            <li>
              <Link
                href="/#aboutus"
                className={styles.link}
                onClick={() => handleNavigation("/#aboutus")}
              >
                Despre noi
              </Link>
            </li>
            <li>
              <Link
                href="/blog"
                className={styles.link}
                onClick={() => handleNavigation("/blog")}
              >
                Blog
              </Link>
            </li>
          </ul>
        </div>

        <div className={styles.column}>
          <h3 className={styles.title}>Cont</h3>
          <ul className={styles.list}>
            <li>
              <Link to="/signin" className={styles.link}>
                Logare
              </Link>
            </li>
            <li>
              <a href="/terms" className={styles.link}>
                Termeni
              </a>
            </li>
            <li>
              <a href="#" className={styles.link}>
                Confidentialitate
              </a>
            </li>
          </ul>
        </div>

        <div className={styles.column}>
          <h3 className={styles.title}>Inscrie-te la newsletter</h3>
          <div className={styles.newsletter}>
            <input
              type="email"
              placeholder="Adresa ta de mail"
              className={styles.input}
            />
            <button className={styles.submitButton}>
              <img src={SendEmail} alt="Submit" />
            </button>
          </div>
        </div>
      </div>

      <div className={styles.bottom}>
        <p className={styles.bottomText}>
          toate drepturile rezervate © 2024 ctrl.fund
        </p>
      </div>
    </footer>
  );
}
