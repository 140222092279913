import React, { useState, useEffect, useRef } from 'react';
import styles from './Chat.module.css'; // Import the CSS module
import { Button, Spinner, Icon } from "@chakra-ui/react"
import { IoClose, IoSend, IoChatbubbleEllipses } from "react-icons/io5";
import axiosInstance from '../../services/axios';

function Chat(props) {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false); // Changed to false initially
    const [isTyping, setIsTyping] = useState(false);
    const [threadId, setThreadId] = useState(null);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const initializeChat = async () => {
        try {
            const response = await axiosInstance.get('/chat/initiate/' + props.fundingId, { withCredentials: true });
            console.log(response);
            const data = await response.data;
            console.log(data);
            console.log(data.thread_id);
            setThreadId(data.thread_id);
            setMessages([{ text: 'Hi there! How can I help you today?', sender: 'bot' }]);
        } catch (error) {
            console.error('Error initializing chat:', error);
            setMessages([{ text: 'Error initializing chat. Please try again.', sender: 'system' }]);
        }
    };

    const sendMessage = async () => {
        if (!input.trim() || !threadId) return;

        setIsLoading(true);
        setMessages((prevMessages) => [...prevMessages, { text: input, sender: 'user' }]);
        setInput('');

        // try {
        //     // const response = await axiosInstance.post('/chat/', {
        //     //     withCredentials: true,
        //     //     body: JSON.stringify({ message: input, thread_id: threadId }),
        //     // });
        //     const response = await axiosInstance.post('/chat/demo', {
        //         withCredentials: true,
        //         message: input,
        //     });

        //     const reader = response.body.getReader();
        //     const decoder = new TextDecoder('utf-8');
        //     let botMessage = '';  // Initialize botMessage outside the loop

        //     setIsTyping(true); // Show typing indicator

        //     while (true) {
        //         const { done, value } = await reader.read();
        //         if (done) break;
        //         const chunk = decoder.decode(value, { stream: true });

        //         // Use a local variable for the current message state
        //         botMessage += chunk;
        //         const currentMessage = botMessage;

        //         setMessages((prevMessages) => {
        //             const lastMessage = prevMessages[prevMessages.length - 1];

        //             if (lastMessage?.sender === 'bot') {
        //                 // Update the last message if it's from the bot
        //                 const updatedMessages = [...prevMessages];
        //                 updatedMessages[updatedMessages.length - 1].text = currentMessage;
        //                 return updatedMessages;
        //             } else {
        //                 // Add a new message if the last one is not from the bot
        //                 return [...prevMessages, { text: currentMessage, sender: 'bot' }];
        //             }
        //         });
        //     }
        // } catch (error) {
        //     console.error('Error sending message:', error);
        //     setMessages((prevMessages) => [...prevMessages, { text: 'Error sending message', sender: 'system' }]);
        // } finally {
        //     setIsTyping(false); // Hide typing indicator
        //     setIsLoading(false);
        // }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}chat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message: input, thread_id: threadId }),
            });
    
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let botMessage = '';  // Initialize botMessage outside the loop
    
            setIsTyping(true); // Show typing indicator
    
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                const chunk = decoder.decode(value, { stream: true });
    
                // Use a local variable for the current message state
                botMessage += chunk;
                const currentMessage = botMessage;
    
                setMessages((prevMessages) => {
                    const lastMessage = prevMessages[prevMessages.length - 1];
                    
                    if (lastMessage?.sender === 'bot') {
                        // Update the last message if it's from the bot
                        const updatedMessages = [...prevMessages];
                        updatedMessages[updatedMessages.length - 1].text = currentMessage;
                        return updatedMessages;
                    } else {
                        // Add a new message if the last one is not from the bot
                        return [...prevMessages, { text: currentMessage, sender: 'bot' }];
                    }
                });
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages((prevMessages) => [...prevMessages, { text: 'Error sending message', sender: 'system' }]);
        } finally {
            setIsTyping(false); // Hide typing indicator
            setIsLoading(false);
        }
    };

    const handleOpenChat = () => {
        setIsOpen(true);
        if (!threadId) {
            initializeChat();
        }
    };

    return (
        <>
            {isOpen ? (
                <div className={`${styles.chatContainer} ${styles.open}`}>
                    <div className={styles.chatHeader}>
                        <span>Chat with us!</span>
                        <Icon as={IoClose} className={styles.closeIcon} onClick={() => setIsOpen(false)} />
                    </div>
                    <div className={styles.messagesContainer}>
                        {messages.map((msg, index) => (
                            <div key={index} className={`${styles.message} ${styles[msg.sender]}`}>
                                <div className={styles.messageBubble}>{msg.text}</div>
                            </div>
                        ))}
                        {isTyping && (
                            <div className={`${styles.message} ${styles.bot}`}>
                                <div className={`${styles.messageBubble} ${styles.typing}`}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        )}
                        <div ref={messagesEndRef} />
                    </div>
                    <div className={styles.inputContainer}>
                        <input
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                            disabled={isLoading}
                            placeholder="Type a message..."
                        />
                        <Button 
                            onClick={sendMessage} 
                            disabled={isLoading}
                            className={styles.sendButton}
                        >
                            {isLoading ? <Spinner size="sm" /> : <Icon as={IoSend} />}
                        </Button>
                    </div>
                </div>
            ) : (
                <Button className={styles.openChatButton} onClick={handleOpenChat}>
                    <Icon as={IoChatbubbleEllipses} />
                </Button>
            )}
        </>
    );
}

export default Chat;