import React, { useEffect, useRef } from "react";
import Header from "./Header";
import Hero from "./Hero";
import SuccessStories from "./SuccessStories";
import FinancingOptions from "./FinancingOptions";
import HowItWorks from "./HowItWorks";
import Pricing from "./Pricing";
import FundInfo from "./AboutUs/FundInfo";
import Footer from "./Footer";

import landingStyles from "./Landing.module.css";

// import Hotjar from '@hotjar/browser';

// const siteId = 5098032;
// const hotjarVersion = 6;

// Hotjar.init(siteId, hotjarVersion);

function Landing4() {
  const heroRef = useRef(null);
  const successStoriesRef = useRef(null);
  const financingOptionsRef = useRef(null);
  const howItWorksRef = useRef(null);
  const pricingRef = useRef(null);
  const fundInfoRef = useRef(null);

  // Function to scroll to a specific ref
  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Scroll to section based on URL hash on initial load
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#hero") {
      scrollToRef(heroRef);
    } else if (hash === "#success-stories") {
      scrollToRef(successStoriesRef);
    } else if (hash === "#financing-options") {
      scrollToRef(financingOptionsRef);
    } else if (hash === "#how-it-works") {
      scrollToRef(howItWorksRef);
    } else if (hash === "#pricing") {
      scrollToRef(pricingRef);
    } else if (hash === "#fund-info") {
      scrollToRef(fundInfoRef);
    }
  }, []);

  return (
    <div>
      <div className={landingStyles.container}>
        <Header
          scrollToHero={() => scrollToRef(heroRef)}
          scrollToSuccessStories={() => scrollToRef(successStoriesRef)}
          scrollToFinancingOptions={() => scrollToRef(financingOptionsRef)}
          scrollToHowItWorks={() => scrollToRef(howItWorksRef)}
          scrollToPricing={() => scrollToRef(pricingRef)}
          scrollToFundInfo={() => scrollToRef(fundInfoRef)}
        />
        <div className={landingStyles.separator} />
        <main className={landingStyles.mainContent}>
          <Hero ref={heroRef} />
          <FinancingOptions ref={financingOptionsRef} />
          <HowItWorks ref={howItWorksRef} />
          {/* <Pricing ref={pricingRef} /> */}
          <FundInfo ref={fundInfoRef} />
          <SuccessStories ref={successStoriesRef} />
        </main>
        <br />
      </div>
      <Footer
        scrollToHero={() => scrollToRef(heroRef)}
        scrollToSuccessStories={() => scrollToRef(successStoriesRef)}
        scrollToFinancingOptions={() => scrollToRef(financingOptionsRef)}
        scrollToHowItWorks={() => scrollToRef(howItWorksRef)}
        scrollToPricing={() => scrollToRef(pricingRef)}
        scrollToFundInfo={() => scrollToRef(fundInfoRef)}
      />
    </div>
  );
}

export default Landing4;
