import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import FinanceCard from "./FinanceCard";
import axiosInstance from "../../services/axios";

function FundingScroll({searchResults}) {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = () => {
    if (!hasMore) return;
    const url = `/fundings/load/${items.length}`;
    // get filters
    
    const company_id = localStorage.getItem("current_company_id");
    const searchFilters = localStorage.getItem("searchFilters");

    const params = {
      company_id: company_id,
      ...JSON.parse(searchFilters),
    };
    axiosInstance
      .get(url, { params, withCredentials: true })
      .then((response) => response.data)
      .then((newItems) => {
        if (newItems.length === 0) {
          setHasMore(false);
          return;
        }
        setItems(prevItems => [...prevItems, ...newItems]);
      })
      .catch((error) => console.error("Error:", error));
  };

  // Fetch initial data on mount
  useEffect(() => {
    if (items.length === 0) {
      fetchMoreData();
    }
  }, []);

  // useEffect(() => {
  //   if (searchResults && searchResults.length > 0) {
  //     setItems(searchResults);
  //     setHasMore(false);
  //   } else {
  //     fetchMoreData();
  //   }
  // }, [searchResults]);


  return (
    <div>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Super! Ai vazut toate ofertele!</b>
          </p>
        }
      >
        {items.map((item, index) => (
          <FinanceCard key={index} funding={item} />
        ))}
      </InfiniteScroll>
    </div>
  );
}

export default FundingScroll;
